<template>
	<Table
		:pgLimitGet="100"
		backendDirectory="compra/produtocusto/atualizar/fila"
		:refresh="true"
		:headers="{
            idempresa: {nome: 'Cód. Filial', tipo: 'texto'},
            empresa: {nome: 'Filial', tipo: 'texto'},
            idSubproduto: {nome: 'Cód. Produto', tipo: 'texto'},
            produto: {nome: 'Produto', tipo: 'texto'},
            margem: {nome: 'Margem', tipo: 'porcentagem'},
            dtmovimento: {nome: 'Data e Hora', tipo: 'texto'},
        }"
	>
		<v-col>Produtos em fila de atualização</v-col>
	</Table>
</template>

<script>
import Table from "../Widgets/Table";

export default {
	name: "ComprasFilaAtualizacao",
	components: { Table },
};
</script>

<style scoped>
</style>